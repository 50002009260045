import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ChangeDetectorRef, OnDestroy, HostBinding } from '@angular/core';

@Component({
  selector: 'iscel',
  templateUrl: './iscel.component.html',
  styleUrls: ['./iscel.component.scss'],
})
export class IscelComponent implements OnInit, AfterViewInit, OnDestroy {
  iscell = true;
  label: string;
  
  name: string;
  //test:string;
  type: string;
  format: string;
  searchstr: string = null;
  colorcol: string = null;

  constructor(private el: ElementRef, private renderer: Renderer2, private cd: ChangeDetectorRef) {}

  get labelStr() {
    return this.label.toString();
  }

  get markIndex(): number {
    return this.labelStr.toLowerCase().indexOf(this.searchstr.toLowerCase());
  }

  get beforeMarked() {
    return this.labelStr.substring(
      0,
      this.markIndex
    );
  }

  get marked() {
    return this.labelStr.substring(
      this.markIndex,
      this.markIndex + this.searchstr.length
    );
  }

  get afterMarked() {
    return this.labelStr.substring(
      this.markIndex + this.searchstr.length,
      this.labelStr.length
    );
  }

  ngOnInit(): void {
    if (this.format&&this.type!='datetime') {
      this.label = this.transform(this.label, this.format);
    }
  }

  ngAfterViewInit() {
    if (this.type) {
      switch (this.type) {
        case 'integer':
        case 'float':
        case 'double': {
          this.renderer.setStyle(this.el.nativeElement, 'text-align', 'right');
          break;
        }
      }
    }
  }

  render(val) {
    if (this.format) {
      this.label = this.transform(this.label, this.format);
    }
  }

  transform(value: any, args?: any): any {
    if (value === null) return null;
    if (value === 0) return '0';
    const fractionSize = 1;
    let abs = Math.abs(value);
    const rounder = Math.pow(10, fractionSize);
    const isNegative = value < 0;
    let key = '';
    const powers = {
      Q: Math.pow(10, 15),
      T: Math.pow(10, 12),
      B: Math.pow(10, 9),
      M: Math.pow(10, 6),
      k: 1000,
    };
    //for (let i = 0; i < powers.length; i++) {
    let reduced = abs / powers[args];
    reduced = Math.round(reduced * rounder) / rounder;
    if (reduced >= 1) {
      abs = reduced;
      key = powers[args];
    }
    //}
    return (isNegative ? '-' : '') + abs + key;
  }

  ngOnDestroy() {}
}
